.nav {
  margin-top: -7rem;
}

.content {
  width: 100vw;
  height: 115vh;
  background: url("./home.jpg") no-repeat center center/cover;
  position: relative;
}

.content::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: var(--icon);
  opacity: 0.8;
}

/* @media (min-width: 1540px) {
  .content::before {
    height: 103.3vh;
  }
} */

.activeLink {
  color: var(--line1) !important;
  font-weight: bold;
  border-bottom: 0.2rem solid var(--line2);
}
.activeLink2 {
  color: white !important;
  font-weight: normal;
  border-bottom: 0.2rem solid transparent;
}

.noAfter::after {
  display: none !important;
}

.navbarToggle {
  color: white !important;
}

.bgT {
  background: rgba(0, 0, 0, 0.1) ;
  backdrop-filter: blur(2px);
}

.bgBl {
  background: transparent;
  backdrop-filter: blur(0px);
}

.toggleLink {
  width: max-content;
}

/* .responsiveLogo {
  width: 300px;
} */

.dropdownItem:focus,.dropdownItem:hover {
  color: black !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
@media (max-width: 991px) {
  .Hamburger {
    position: absolute;
    right: 2%;
  }

  .bgBl {
    position: absolute;
    top: 10.5vh;
    right: 8%;
    padding: 1vh 8vw;
    background: linear-gradient(135deg, #011b3d, #3083fd);
    backdrop-filter: blur(3px);
    border-radius: 2%;
    -webkit-border-radius: 2%;
    -moz-border-radius: 2%;
    -ms-border-radius: 2%;
    -o-border-radius: 2%;
  }

  /* [dir="rtl"] .responsiveForm {
    right: -3%;
  } */

  /* .responsiveLogo {
    position: relative;
    right: 9%;
  }

  [dir="rtl"] .responsiveLogo {
    position: relative;
    right: 70vw;

  } */
}

@media (max-width: 768px) {
  .bgBl {
    background: linear-gradient(135deg, #011b3d, #3083fd);
    backdrop-filter: blur(3px);
    border-radius: 2%;
    -webkit-border-radius: 2%;
    -moz-border-radius: 2%;
    -ms-border-radius: 2%;
    -o-border-radius: 2%;
  }

  /* [dir="rtl"] .responsiveLogo {
    position: relative;
    right: 60vw;
  } */
}

/* @media (max-width: 484px) {
  .responsiveForm {
    right: 20%;
  }

  [dir="rtl"] .responsiveForm {
    right: 5%;
  }

  .responsiveLogo {
    right: 15%;
  }
  [dir="rtl"] .responsiveLogo {
    right:27vw;
  }
} */

@media (max-width: 450px) {
  .bgBl {
    top: 10.5vh;
    right: 13%;
  }
}
@media (max-width: 1200px) {
  [dir="rtl"] .noAfter {
    margin: 0 15vw 0 0 !important;
  }
}

@media (max-width: 440px) {
  .content {
    height: 113.5vh;
  }

  /* .content::before {
    height: 98vh;
  } */
}
/* @media (max-width: 386px) {
  [dir="rtl"] .responsiveLogo {
    right:24vw;
  }
}

@media (max-width: 375px) {
  .responsiveLogo {
    width: 150px;
  }
  [dir="rtl"] .responsiveLogo {
    right:50vw;
  }
} */


.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    background-color: #f5f5f5;
}

.slider {
    position: relative;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    /* height: 75vh; */
}

.slider2 {
    height: 40vh;
}

.sliderInner {
    display: flex;
    width: calc(300% * 3);
    transition: transform 0.5s ease-in-out;
}

.contImages {
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    background-color: var(--services);
    margin: auto;
}

.slideImage {
    position: relative;
    top: 20%;
    left: 50%;
    height: 11vh;
    object-fit: scale-down;
    transition: transform 0.3s ease, opacity 0.3s ease;
    -webkit-transition: transform 0.3s ease, opacity 0.3s ease;
    -moz-transition: transform 0.3s ease, opacity 0.3s ease;
    -ms-transition: transform 0.3s ease, opacity 0.3s ease;
    -o-transition: transform 0.3s ease, opacity 0.3s ease;
}

.sliderWrapper {
    display: flex !important;
    width: calc(100% / 3);
}

.slide {
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 5vh 0;
}

.slideIcon {
    font-size: 2em;
    margin-bottom: 10px;
    color: #fff;
}

.slideName {
    font-size: 1.5em;
    margin: 2rem 0;
    color: var(--header);
}

.slidePara {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 15px;
    padding: 0 2vw;
    color: #666;
}

.slideLink {
    text-decoration: none;
    color: #3498db;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    transition: 0.3s ease;
}

.slideLink:hover {
    letter-spacing: 0.5rem;
}

.prev,
.next {
    position: absolute;
    bottom: -15%;
    transform: translateY(-50%);
    background-color: #eee;
    color: #333;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.prev {
    right: 6rem;
}

.next {
    right: 10px;
}

.prev:hover,
.next:hover {
    background-color: #ddd;
}

.swiper {
    width: 100%;
    height: max-content;
}

.swiperSlide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    padding: 4vh 0;
    display: block;
    justify-content: center;
    align-items: center;
}

.image {
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: absolute;
    top: 0;
    width: 420px;
    height: 40vh;
    object-fit: fill;
}

.image2 {
    transition: transform 0.3s ease, opacity 0.3s ease;
    width: 5rem;
    position: absolute;
}

.card:hover .image {
    opacity: 0.5;
    transform: scale(.8);
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.card:hover .overlay {
    opacity: 1;
}

.text {
    color: white;
    font-size: 1.5rem;
    text-align: center;
    padding: 10px;
}

.imageContainer {
    width: 30vw;
    height: 45vh;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.gradientBackground {
    background-color: #011b3d;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 28vh;
}

@media screen and (min-width: 1670px) {
    .swiperSlide {
        height: 50vh !important;
        /* background-color: red !important; */
    }
}
@media screen and (min-width: 1537px) {
    .gradientBackground {
        height: 22vh;
    }
    
}

@media (max-width: 430px) {
    .content {
        padding: 10px;
    }

    .slider {
        width: 80%;
        height: 65vh;
    }

    .slider2 {
        height: 40vh;

    }

    .slide {
        padding: 10px;
    }

    .contImages {
        width: 18vw;
        height: 18vw;
    }
}

@media (max-width: 330px) {
    .slider {
        width: 90%;
        /* height: 65vh; */
    }
}

@media (max-width: 765px) {
    .image {

        width: 620px;

    }
}
@media (max-width: 770px) {
    .gradientBackground {
      height: 31vh;
    }
    /* .slider {
        height: 70vh;
    } */
  }
@media (max-width: 470px) {
    .gradientBackground {
      height: 31vh;
    }
  }

.socialIcons {
    position: relative;
    justify-content: center;
    color: white;
}

.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.followUs {
    margin-bottom: 20px;
    position: relative;
}

.followUs::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 50px;
    background-color: white;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 6vh;
}

.icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #34495e;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.icon:hover {
    background-color: #2c3e50;
    transform: scale(1.1);
}

.icon i {
    font-size: 24px;
    color: white;
}

.facebook {
    background-color: #3b5998;
}

.instagram {
    background-color: #e4405f;
}

.twitter {
    background-color: #55acee;
}

@media (max-width: 440px) {
    .socialIcons {
        flex-direction: row;
        left: 160%;
        margin-bottom: -160vh;
    }

    [dir="rtl"] .socialIcons {
        left: -135%;
        margin-bottom: -160vh;
    }

    .followUs {
        flex-direction: row;
        margin: 3vh 20px 0px 0px;
    }

    .followUs::after {
        top: 100%;
        left: 50%;
        width: 65px;
        height: 2px;
    }

    .iconContainer {
        flex-direction: row;
        margin-top: 3vh;
        margin-right: 5vh;
    }
}

@media (max-width: 490px) {
    .socialIcons {
        position: relative;
        top: 10%;
    }
}
.wave {
  position: relative;
  z-index: 1 !important;
  width: 15rem;
}

/* .gradientBackground {
  background-color: #011b3d;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 28vh;
} */

.wave::after {
  z-index: 1;
  content: "";
  position: absolute;
  border-radius: 45%;
  background-image: url("data:image/svg+xml,%3Csvg%20id%3D%22sw-js-blob-svg%22%20viewBox%3D%220%200%20100%20100%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20version%3D%221.1%22%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22sw-gradient%22%20x1%3D%220%22%20x2%3D%221%22%20y1%3D%221%22%20y2%3D%220%22%3E%3Cstop%20id%3D%22stop1%22%20stop-color%3D%22rgba(230%2C%20230%2C%20230%2C%201)%22%20offset%3D%220%25%22%3E%3C/stop%3E%3Cstop%20id%3D%22stop2%22%20stop-color%3D%22rgba(230%2C%20230%2C%20230%2C%201)%22%20offset%3D%22100%25%22%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath%20fill%3D%22url(%23sw-gradient)%22%20d%3D%22M18.6%2C-26.4C19.9%2C-21%2C13.9%2C-11.2%2C12.5%2C-4.4C11.1%2C2.4%2C14.2%2C6.2%2C14%2C9.6C13.9%2C13.1%2C10.5%2C16.2%2C6.6%2C17.1C2.8%2C18-1.4%2C16.8-9.6%2C17.6C-17.7%2C18.4-29.8%2C21.3-33.9%2C17.8C-38%2C14.3-34.1%2C4.5-28.1%2C-0.6C-22%2C-5.8-13.8%2C-6.2-8.8%2C-10.9C-3.7%2C-15.6-1.9%2C-24.6%2C3.4%2C-28.6C8.6%2C-32.7%2C17.2%2C-31.8%2C18.6%2C-26.4Z%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20transform%3D%22translate(50%2050)%22%20stroke-width%3D%220%22%20style%3D%22transition%3A%200.3s%3B%22%20stroke%3D%22url(%23sw-gradient)%22%3E%3C/path%3E%3C/svg%3E");
  width: 44vw;
  height: 44vw;
  background-repeat: no-repeat;
  background-position: center;
  left: -65%;
  transform: rotate(137deg);
  -webkit-border-radius: 45%;
  -moz-border-radius: 45%;
  -ms-border-radius: 45%;
  -o-border-radius: 45%;
  -webkit-transform: rotate(137deg);
  -moz-transform: rotate(137deg);
  -ms-transform: rotate(137deg);
  -o-transform: rotate(137deg);
}

[dir="rtl"] .wave:before {
  width: 90vw;
  right: -20%;
  height: 470px;
}

[dir="rtl"] .Image2 {
  position: absolute;
  margin: 25%;
  z-index: 233;
}
.Image2 {
  position: absolute;
  margin: 65% 35%;
  z-index: 233;
}

.mbb {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  position: relative;
}

.line2 {
  /* background-color: var(--header);
  width: 10vw;
  height: 4px;
  position: absolute;
  left: 3%;
  margin-bottom: 6vh; */
  padding-bottom: 2vh;
  border-bottom: 4px solid var(--header);
  width: fit-content;
}

.mm {
  margin: 3rem;
}

[dir="rtl"] .wave::after {
  position: absolute;
  width: 45vw;
  height: 51vw;
  left: -120%;
  top: -19vh;
  transform: rotate(137deg);
  -webkit-transform: rotate(137deg);
  -moz-transform: rotate(137deg);
  -ms-transform: rotate(137deg);
  -o-transform: rotate(137deg);
}

/* [dir="rtl"] .line2 {
  position: absolute;
  right: 0%;
  background-color: var(--header);
  width: 7vw;
  height: 4px;
  margin: 0 5px 5vw;
  margin-bottom: 6vh;
} */

@media (max-width: 1300px) {
  [dir="rtl"] .wave::after {
    width: 50vw;
    height: 50vw;
    left: -110%;
    top: -10vh;
  }
  .wave::after {
    width: 54vw;
    height: 50vw;
    left: -65%;
  }
}
@media (max-width: 1200px) {
  .wave::after {
    left: -59%;
    top: 0vh;
  }
}

@media (max-width: 1155px) {
  [dir="rtl"] .wave::after {
    width: 57vw;
    height: 55vw;
    left: -110%;
    top: -7vh;
  }

  .wave::after {
    left: -52%;
    top: -0vh;
  }
}
@media (max-width: 1090px) {
  [dir="rtl"] .wave::after {
    width: 67vw;
    height: 65vw;
    left: -130%;
    top: -7vh;
  }

  .wave::after {
    width: 67vw;
    height: 65vw;
    left: -80%;
    top: -7vh;
  }
}

@media (max-width: 1024px) {
  .wave::after {
    width: 67vw;
    height: 68vw;
    left: -71%;
  }

  [dir="rtl"] .wave::after {
    width: 58vw;
    height: 47vw;
    left: -110%;
    transform: rotate(207deg);
    -webkit-transform: rotate(207deg);
    -moz-transform: rotate(207deg);
    -ms-transform: rotate(207deg);
    -o-transform: rotate(207deg);
  }
}

@media (max-width: 768px) {
  .wave::after {
    width: 81vw;
    height: 85vw;
    left: -48%;
  }

  [dir="rtl"] .wave::after {
    width: 83vw;
    height: 83vw;
    left: -110%;
    top: -19vh;
    transform: rotate(133deg);
    -webkit-transform: rotate(133deg);
    -moz-transform: rotate(133deg);
    -ms-transform: rotate(133deg);
    -o-transform: rotate(133deg);
}
}

@media (max-width: 650px) {
  .wave {
    display: none;
  }
}
@media (max-width: 430px) {

  .mm {
    margin: 1rem;
    padding: 1rem;
  }

  .mbb {
    margin-bottom: 0.5rem;
    padding-bottom: 0rem;
  }
}

/* @media screen and (min-width: 1537px) {
  .gradientBackground {
    height: 22vh;
  }
}


@media (max-width: 770px) {
  .gradientBackground {
    height: 25vh;
  }
}
@media (max-width: 470px) {
  .gradientBackground {
    height: 25vh;
  }
} */

.line {
  background-color: var(--line2);
  width: 10vw;
  height: 4px;
  margin: auto;
  margin-bottom: 6vh;
}

.card {
  width: 26vw;
  background: var(--header);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin: 3vh 15vh 3vh 22vh;
  padding: 13vh 5vw;
  height: 75vh;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.circularImage {
  width: 140px;
  height: 140px;
  background-color: rgba(128, 128, 128, 0.216);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.circularImage:hover{
  background-color: transparent;

}

.circularImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: transparent;
  transition: border 0.3s ease-in-out;
}

.circularImage:hover::before {
  border-top-color: var(--header);
  animation: spin 2s linear forwards;
  -webkit-animation: spin 2s linear forwards;
}

@keyframes spin {
  0% {
    border-top-color: var(--header);
    transform: rotate(0deg);
  }
  25% {
    border-right-color: var(--header);
    transform: rotate(90deg);
  }
  50% {
    border-bottom-color: var(--header);
    transform: rotate(180deg);
  }
  75% {
    border-left-color: var(--header);
    transform: rotate(270deg);
  }
  100% {
    border-color: var(--header);
    transform: rotate(360deg);
  }
}
.serviceH:hover p {
  color: var(--header);
}

.wavy:before {
    z-index: -5;
    content: "";
    position: absolute;
    border-radius: 10%;
    width: 115vw;
    height: 80vw;
    background-image:    url("data:image/svg+xml,%3Csvg%20id%3D%22sw-js-blob-svg%22%20viewBox%3D%220%200%20100%20100%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20version%3D%221.1%22%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22sw-gradient%22%20x1%3D%220%22%20x2%3D%221%22%20y1%3D%221%22%20y2%3D%220%22%3E%3Cstop%20id%3D%22stop1%22%20stop-color%3D%22rgba(230%2C%20230%2C%20230%2C%201)%22%20offset%3D%220%25%22%3E%3C/stop%3E%3Cstop%20id%3D%22stop2%22%20stop-color%3D%22rgba(230%2C%20230%2C%20230%2C%201)%22%20offset%3D%22100%25%22%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath%20fill%3D%22url(%23sw-gradient)%22%20d%3D%22M18.6%2C-26.4C19.9%2C-21%2C13.9%2C-11.2%2C12.5%2C-4.4C11.1%2C2.4%2C14.2%2C6.2%2C14%2C9.6C13.9%2C13.1%2C10.5%2C16.2%2C6.6%2C17.1C2.8%2C18-1.4%2C16.8-9.6%2C17.6C-17.7%2C18.4-29.8%2C21.3-33.9%2C17.8C-38%2C14.3-34.1%2C4.5-28.1%2C-0.6C-22%2C-5.8-13.8%2C-6.2-8.8%2C-10.9C-3.7%2C-15.6-1.9%2C-24.6%2C3.4%2C-28.6C8.6%2C-32.7%2C17.2%2C-31.8%2C18.6%2C-26.4Z%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20transform%3D%22translate(50%2050)%22%20stroke-width%3D%220%22%20style%3D%22transition%3A%200.3s%3B%22%20stroke%3D%22url(%23sw-gradient)%22%3E%3C/path%3E%3C/svg%3E");
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position: center;
    right: 45%;
    top: 6%;
    -webkit-border-radius: 10%;
    -moz-border-radius: 10%;
    -ms-border-radius: 10%;
    -o-border-radius: 10%;
}


@media (max-width: 1024px) {
    .card {
        width: 30vw;
        margin: 3vh 12vh 3vh 12vh;
        padding: 13vh 5vw;
        height: 85vh;
      }
  }
@media (max-width: 768px) {
    .card {
        width: 50vw;
        margin: 3vh 3vh 3vh 3vh;
        padding: 5vh 5vw;
        height: max-content;
      }
      .wavy:before {
        width: 200vw;
        height: 130vw;
        right: 10%;
        top: -10%;
    }
  }
  @media (max-width: 430px) {
    .wavy:before {
        width: 200vw;
        height: 230vw;
        right: 10%;
        top: 7%;
    }
    .card {
        width: 80vw;
        margin: 2vh 2vh 2vh 2vh;
        padding: 6vh 6vw;
        height: max-content;
      }
    }

    /* @media (max-width: 770px) {
      .gradientBackground {
        height: 25vh;
      }
    }
  @media (max-width: 470px) {
      .gradientBackground {
        height: 25vh;
      }
    } */
  
@tailwind base;
@tailwind components;
@tailwind utilities;

/*

   icon back-ground 3E5161
    2*our development process point&back 9F3144 264D6F 366D8A 5B9DAE
     3-our services bg A7B1BB button 829DC5 phargrah 5588E0 212121
      4*our teem bg ECEEF0 212121
       text footer 3E5161
*/

:root {
  --header: #3083fd;
  --backgroundColor: #ffffff;
  --line1: #357efe;
  --line2: #fd4eee;
  --button: #2a84fe;
  --icon: #3e5161;
  --process1: #9f3144;
  --process2: #264d6f;
  --process3: #366d8a;
  --process4: #5b9dae;
  --services: #a7b1bb17;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  scroll-behavior: smooth;   
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 0.8rem;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

html::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5rem;
}

.nav-link {
  color: var(--backgroundColor) !important;
  font-weight: bold;
  padding: 1rem;
  border-bottom: 0.2rem solid transparent !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.nav-link:hover {
  color: var(--header) !important;
  border-bottom: 0.2rem solid var(--line2) !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

a {
  text-decoration: none;
}

footer a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.927);
  font-size: 23px;
}

footer ul {
  margin: 0px !important;
  padding: 0px !important;
}

body {
  overflow-x: hidden;
}

.lineAround {
  position: relative;
  padding: 4vh 3vw;
  border-left: 4px solid var(--header);
  background: linear-gradient(to right, var(--header) 20%, transparent 20%) top
      left/100% 4px no-repeat,
    linear-gradient(to right, var(--header) 20%, transparent 20%) bottom
      left/100% 4px no-repeat,
    transparent;
}
[dir="rtl"] .lineAround {
  border-left: 0px solid var(--header);
  border-right: 4px solid var(--header);
  background: linear-gradient(to left, var(--header) 30%, transparent 20%) top
      right/100% 4px no-repeat,
    linear-gradient(to left, var(--header) 30%, transparent 20%) bottom
      right/100% 4px no-repeat,
    transparent;
}

.lineAround1::before {
  content: "";
  position: absolute;
  width: 4px;
  top: 0%;
  left: 20%;
  transform: translateX(-50%);
  z-index: 23;
  height: 5vh;
  background-color: var(--header);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.lineAround1::after {
  content: "";
  position: absolute;
  width: 4px;
  bottom: 0%;
  left: 20%;
  transform: translateX(-50%);
  z-index: 23;
  height: 4.5vh;
  background-color: var(--header);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
[dir="rtl"] .lineAround1::before {
  right: 29.4% !important;
}
[dir="rtl"] .lineAround1::after {
  right: 29.4% !important;
}

@media (max-width: 1024px) {
  .navDirection {
    position: relative;
    top: 7vh;
    left: -70%;
  }

  .content {
    position: relative;
    left: 25%;
    top: 10%;
    width: 70vw;
  }

  [dir="rtl"] .content {
    left: -10%;
  }

  .implement {
    margin: 3vh 0;
  }
}

@media (max-width: 976px) {
  .content {
    width: 80vw;
  }
}

@media (max-width: 768px) {
  .content {
    left: 20%;
  }

  [dir="rtl"] .content {
    left: -30%;
  }
}

@media (max-width: 639px) {
  [dir="rtl"] .content {
    top: -80% !important;
  }

  .content {
    top: -100%;
  }
}

@media (max-width: 545px) {
  .content {
    left: 20%;
  }
}

@media (max-width: 490px) {
  .content {
    top: -80%;
    left: 30%;
    width: 60vw;
  }
}

@media (max-width: 440px) {
  .content {
    top: 15%;
    left: -5%;
    width: fit-content;
  }

  [dir="rtl"] .content {
    top: 10% !important;
    left: 0%;
  }
}

@media (max-width: 390px) {
  .content {
    left: -2%;
  }

  [dir="rtl"] .content {
    left: 1%;
  }
}
@media (max-width: 330px) {
  .content {
    left: -8%;
  }

  [dir="rtl"] .content {
    left: 0%;
  }
}

.navbar-toggler {
  background-color: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(2px);
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

.contact {
  transition: 3s linear;
  scroll-behavior: smooth;
  -webkit-transition: 3s linear;
  -moz-transition: 3s linear;
  -ms-transition: 3s linear;
  behavior: inherit;
  -o-transition: 3s linear;
}
